import context from '@truckdown/components-systems';
import registerLocations from '@truckdown/locations';
import components from '@truckdown/components-locations';

registerLocations(context);
components.registerPrerequisites(context);

context.initialize().then(() => {
    (window as any).isSysInitialized = true;
    console.log('System initialized');
});